import { requireFun } from '@/utils'
import { charLenLimitConstructor, isW, isRobust, isEmail, validateSpecialCharacter } from '@/utils/validate'
let is6And30char = charLenLimitConstructor(2, 30)
export const addUserField = [
  {
    label: '用户昵称',
    prop: 'nickName',
    name: 'nickName',
    colNum: 24,
    placeholder: '请输入用户昵称',
    rule: [
      {
        required: true,
        message: requireFun('用户昵称')
      },
      {
        validator: is6And30char
      },
      {
        validator: validateSpecialCharacter
      }
    ]
  },
  {
    label: '登录账号',
    prop: 'accountName',
    name: 'accountName',
    colNum: 24,
    placeholder: '请输入登录账号',
    rule: [
      {
        required: true,
        message: requireFun('登录账号')
      },
      {
        validator: charLenLimitConstructor(4, 10)
      },
      {
        validator: isW
      }
    ]
  },
  {
    label: '用户邮箱',
    prop: 'email',
    name: 'email',
    colNum: 24,
    placeholder: '请输入用户邮箱',
    rule: [
      {
        required: true,
        message: requireFun('用户邮箱')
      },
      {
        validator: charLenLimitConstructor(8, 50)
      },
      {
        validator: isEmail
      }
    ]
  },
  {
    label: '设置密码',
    prop: 'password',
    name: 'password',
    placeholder: '请输入密码',
    colNum: 24,
    type: 'password',
    rule: [
      {
        required: true,
        message: requireFun('密码')
      },
      {
        validator: isRobust
      }
    ]
  },
  {
    label: '确认密码',
    prop: 'confirmPassword',
    name: 'confirmPassword',
    colNum: 24,
    type: 'password',
    placeholder: '请再次输入密码',
    rule: [
      {
        required: true,
        message: requireFun('确认密码')
      },
      {
        validator: isRobust
      }
    ]
  },
  {
    label: '所属供应商',
    prop: 'supplierId',
    name: 'supplierId',
    colNum: 24,
    slotName: 'supplierSlot',
    rule: [
      {
        required: true,
        message: requireFun('供应商')
      }
    ]
  },
  {
    label: '选择角色',
    prop: 'roleIdList',
    name: 'roleIdList',
    colNum: 24,
    slotName: 'rolesSlot',
    rule: [
      {
        required: true,
        message: requireFun('选择角色')
      }
    ]
  }
]

export const editUserField = [
  {
    label: '用户昵称',
    prop: 'nickName',
    name: 'nickName',
    colNum: 24,
    placeholder: '请输入用户昵称',
    rule: [
      {
        required: true,
        message: requireFun('用户昵称')
      },
      {
        validator: is6And30char
      },
      {
        validator: validateSpecialCharacter
      }
    ]
  },
  {
    label: '登录账号',
    prop: 'accountName',
    name: 'accountName',
    disabled: true,
    colNum: 24,
    placeholder: '请输入登录账号',
    rule: [
      {
        required: true,
        message: requireFun('登录账号')
      },
      {
        validator: charLenLimitConstructor(4, 10)
      },
      {
        validator: isW
      }
    ]
  },
  {
    label: '用户邮箱',
    prop: 'email',
    name: 'email',
    colNum: 24,
    placeholder: '请输入用户邮箱',
    rule: [
      {
        required: true,
        message: requireFun('用户邮箱')
      },
      {
        validator: charLenLimitConstructor(8, 50)
      },
      {
        validator: isEmail
      }
    ]
  },
  {
    label: '所属供应商',
    prop: 'supplierId',
    name: 'supplierId',
    colNum: 24,
    slotName: 'supplierSlot',
    rule: [
      {
        required: true,
        message: requireFun('供应商')
      }
    ]
  },
  {
    label: '选择角色',
    prop: 'roleIdList',
    name: 'roleIdList',
    colNum: 24,
    slotName: 'rolesSlot',
    rule: [
      {
        required: true,
        message: requireFun('选择角色')
      }
    ]
  }
]
