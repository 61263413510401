var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container baseUserRoleComponent app-flex-col-container"},[_c('div',{staticClass:"flex-col-content"},[_c('div',{staticClass:"title"},[_c('i',{staticClass:"el-icon-d-arrow-left",on:{"click":_vm.back}}),_c('span',[_vm._v(_vm._s(_vm.userTitle))])]),_c('BaseForm',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",staticStyle:{"width":"50%"},attrs:{"size":"small","label-width":"100PX","cols":_vm.formField,"form":_vm.form},scopedSlots:_vm._u([{key:"nameSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-input',{staticStyle:{"width":"250px"},attrs:{"size":"small","type":"text","placeholder":placeholder},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}})]}},{key:"passwordSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-input',{attrs:{"placeholder":placeholder,"type":"password","show-password":"","auto-complete":"on"},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}})]}},{key:"password2Slot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-input',{attrs:{"placeholder":placeholder,"show-password":"","type":"password","auto-complete":"on"},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}})]}},{key:"supplierSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('SupplierSelectList',{staticStyle:{"width":"250px"},attrs:{"menus":_vm.supplierId,"keyObj":_vm.supplierIdProps,"keyword":_vm.form[prop],"placeholder":placeholder},on:{"update:keyword":function($event){return _vm.$set(_vm.form, prop, $event)}}})]}},{key:"rolesSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('RoleSelectList',{staticStyle:{"width":"250px"},attrs:{"multiple":"","menus":_vm.factoryRoleList,"keyObj":_vm.factoryRoleListProps,"keyword":_vm.form[prop],"placeholder":placeholder},on:{"update:keyword":function($event){return _vm.$set(_vm.form, prop, $event)}}})]}}])})],1),_c('div',{staticClass:"btn-wrapper bottom"},[_c('el-button',{on:{"click":_vm.back}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.doSubmit}},[_vm._v(" 提交 ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }