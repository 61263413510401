<template>
  <div class="app-container baseUserRoleComponent app-flex-col-container">
    <div class="flex-col-content">
      <div class="title">
        <i class="el-icon-d-arrow-left" @click="back"></i>
        <span>{{ userTitle }}</span>
      </div>
      <BaseForm
        style="width: 50%"
        v-loading="loading"
        size="small"
        ref="form"
        label-width="100PX"
        :cols="formField"
        :form="form"
      >
        <template #nameSlot="{ scoped: { prop, placeholder } }">
          <el-input size="small" type="text" style="width: 250px" :placeholder="placeholder" v-model="form[prop]" />
        </template>

        <template #passwordSlot="{ scoped: { prop, placeholder } }">
          <el-input v-model="form[prop]" :placeholder="placeholder" type="password" show-password auto-complete="on" />
        </template>

        <template #password2Slot="{ scoped: { prop, placeholder } }">
          <el-input v-model="form[prop]" :placeholder="placeholder" show-password type="password" auto-complete="on" />
        </template>

        <template #supplierSlot="{ scoped: { prop, placeholder } }">
          <SupplierSelectList
            style="width: 250px"
            :menus="supplierId"
            :keyObj="supplierIdProps"
            :keyword.sync="form[prop]"
            :placeholder="placeholder"
          />
        </template>

        <template #rolesSlot="{ scoped: { prop, placeholder } }">
          <RoleSelectList
            style="width: 250px"
            multiple
            :menus="factoryRoleList"
            :keyObj="factoryRoleListProps"
            :keyword.sync="form[prop]"
            :placeholder="placeholder"
          />
        </template>
      </BaseForm>
    </div>
    <div class="btn-wrapper bottom">
      <el-button @click="back"> 取消 </el-button>
      <el-button type="primary" @click="doSubmit" :loading="loading"> 提交 </el-button>
    </div>
  </div>
</template>

<script>
import SupplierSelectList from '../supplierSelectList'
import RoleSelectList from '../roleSelectList'
import { addUserField, editUserField } from './formField'
import { commonFromMixin } from '@/mixins'
import { add, read, edit } from '@/api/supplier'
import { deepClone } from '@/components/avue/utils/util'
import { mapGetters } from 'vuex'
export default {
  mixins: [commonFromMixin],
  components: {
    SupplierSelectList,
    RoleSelectList
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters(['factoryRoleList', 'factoryRoleListProps', 'supplierId', 'supplierIdProps']),
    userTitle() {
      return this.isEdit ? '编辑供应商账号' : '添加供应商账号'
    }
  },
  data() {
    if (!this.isEdit) {
      const confirmPass = (rule, value, callback) => {
        if (this.form.password !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          callback()
        }
      }
      addUserField[4].rule[2] = {
        validator: confirmPass
      }
    }
    return {
      formField: this.isEdit ? editUserField : addUserField,
      id: this.$route.query.id,
      form: {
        // 解决 添加时，roleIdList表单会提示信息
        roleIdList: []
      },
      loading: false,
      loading1: false,
      oldRoleIdList: []
    }
  },
  created() {
    this.initEdit()
  },
  methods: {
    back() {
      this.$router.replace('/supplier/account')
    },
    async initEdit() {
      if (!this.isEdit) return
      if (!this.id) {
        this.back()
      }
      const { code, detail } = await read(this.id)
      if ($SUC({ code })) {
        const { nickName, accountName, email, supplierId, roleDTOList } = detail || {}
        const roleIdList = (roleDTOList || []).map((item) => {
          return item.id
        })
        this.oldRoleIdList = roleIdList
        this.form = {
          accountName,
          nickName,
          email,
          supplierId,
          roleIdList: roleIdList
        }
        const { form } = this.$refs
        form && form.resetFields()
      }
    },
    async doSubmit() {
      const valid = await this.validate()
      if (!valid) return
      if (this.isEdit) {
        awaitLoading(this.doEdit())
      } else {
        awaitLoading(this.doAdd())
      }
    },
    async doAdd() {
      this.loading = true
      try {
        const { code } = await add(this.form)
        if ($SUC({ code })) {
          this.success()
          this.back()
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async doEdit() {
      this.loading = true
      const nowRoleIdList = this.form.roleIdList
      const oldRoleIdList = this.oldRoleIdList
      const addList = nowRoleIdList.filter((id) => !oldRoleIdList.includes(id))
      const delList = oldRoleIdList.filter((id) => !nowRoleIdList.includes(id))
      try {
        const tempObj = deepClone(this.form)
        tempObj.id = this.id
        delete tempObj.roleIdList
        tempObj.createRoleIdList = addList
        tempObj.delRoleIdList = delList
        const { code } = await edit(tempObj)
        if ($SUC({ code })) {
          this.success()
          this.back()
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.baseUserRoleComponent {
  padding: 30px;
  .title {
    padding: 15px;
    background: #fff;
    i {
      margin-right: 10px;
    }
  }
  .btn-wrapper {
    margin-top: 30px;
    overflow: auto;
    text-align: center;
  }
}
</style>
