import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function add(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/createSupplierUser',
    method: 'post',
    data
  })
}
export function read(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/getUserById',
    method: 'post',
    data
  })
}

export function edit(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/updateUser',
    method: 'post',
    data
  })
}
